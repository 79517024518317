export const environment = {
  firebase: {
    apiKey: "AIzaSyDJMXk_A--KEpZon8wEEwCRhoNadCE_ByA",
    authDomain: "wibo-developer.firebaseapp.com",
    databaseURL: "https://wibo-developer.firebaseio.com/",
    projectId: "wibo-developer",
    storageBucket: "wibo-developer.appspot.com",
    messagingSenderId: "528973705596",
    appId: "1:528973705596:web:290318843a12958c4e74f9",
    measurementId: "G-7N0QPYEP4W"
  },
  production: false,
  api_url: 'https://bubba-staging.wibodev.com',
  // api_url: 'http://localhost:8000',
  auth_token_key: 'authce9d77b308c149d5992a80073637e4d5',
  site_id: '', //Este campo debe ser generado cada vez que se cree una pwa
  version: '2.2.0',
  support: {
    type: 'chat',
    url: 'https://ayuda.holadary.com/'
  }
};
